@import '../../_variables.scss';

.referencial {
    .view-title {
        font-size: 18px;
        font-weight: bold;
        height: $referencial-view-title-height;
    }

    .header-content {
        height: $referencial-header-content-height;
        margin-bottom: $referencial-header-content-margin-bottom;
    }

    .custom-checkbox {
        height: 20px;
        width: 20px;
    }

    .referential-tooltip{
        cursor: pointer;
    }
}
