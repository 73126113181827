@import '../../variables';
@import '../../shared/common';

.component-content {
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: 100% !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.component-content-shiftRight {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-right: $drawer-margin-width;
    flex-grow: 1;
    margin-left: 0;
}

.alerte-message {
    color: $information-message-color;
    font-size: 0.8em;
    margin-left: 1em;
    font-weight: 500;
}

.transport-sale-component-content {
    flex-grow: 1;
    width: 100%;
}

.content-header {
    .top-filter {
        padding: $vehicle-header-content-padding-top-bottom 0;

        .btn-calendar {
            margin-right: 5px;
        }

        label {
            margin-bottom: unset;
        }

        .filter-checkbox .MuiCheckbox-root {
            padding: unset !important;
        }

        .btn-actions-container {
            margin-left: 10px;
        }

        .btn-actions {
            background-color: $icon-camera-color;

            .icon-camera {
                color: white;
            }
        }

        .send-zephyr-btn {
            cursor: pointer;

            &.btn-disabled {
                cursor: default;
            }
        }

        .btn-margin {
            background-color: $button-track-flow-background-color;

            .btn-icon {
                color: $button-icon-open-drawer-color;
            }

            .Mui-disabled {
                color: gray;
            }
        }

        .txt-integration {
            color: white !important;
        }

        .btn-actions-disabled {
            background-color: gray !important;
        }

        .nb-transportFlow-todo-invoice {
            color: $color-nb-todo-invoice;
            font-size: 0.8em;
        }

        .logistics-unit-options {
            .options-filter__menu {
                z-index: 2;
            }
        }
    }

    .divider-filter {
        background-color: $border-color;
        height: 1px;
        //padding-top: 1px;
        //margin-top: 0.6rem;
    }

    .filter-group-buttons {
        .filter-title {
            color: $filter-color;
            font-size: 14px;
            min-height: 1.5rem;
        }

        #transportRequestId, #receiverSite, #payer, #payerJobSiteMdmId {
            color: white;
            background-color: $filter-button-not-selected;
            margin-right: .5rem !important;
        }

        #transportRequestId.selected, #receiverSite.selected, #payer.selected, #payerJobSiteMdmId.selected {
            color: $title-color !important;
            background-color: $filter-button-selected !important;
        }

        .preinvoice-costs-table {
            border-collapse: separate;
            border-spacing: 0;
            background-color: $header-grid;
            min-width: 250px;

            tr:first-child th:first-child {
                border-top-left-radius: 4px;
            }

            tr:first-child th:last-child {
                border-top-right-radius: 4px;
            }

            tr:last-child td:first-child {
                border-bottom-left-radius: 4px;
            }

            tr:last-child td:last-child {
                border-bottom-right-radius: 4px;
            }

            thead {
                background-color: unset;
            }

            tr th {
                border-top: 1px solid $border-color;
                height: 22px;
            }

            tr th:first-child, tr td:first-child {
                border-left: 1px solid $border-color;
            }

            tr td, tr th {
                border-right: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
            }

            tr td {
                height: 32px;
            }

            td.center-content, th.center-content {
                text-align: center;
                font-weight: bold;
                font-size: 12px;
            }

            td.left-content, th.left-content {
                padding-left: 2px;
                font-weight: bold;
                font-size: 12px;
            }

            td.title {
                color: $title-color !important;
            }

            td.value {
                color: black !important;
            }
        }
    }
}

.input-search-vehicles {
    height: 40px !important;
    border-radius: 21.5px;
    background-color: $input-background-color;
    padding-right: unset;

    .icon-search {
        width: 56px;
        color: white;
    }

    .input-icon-close-root, .input-icon-search-root {
        max-height: unset;
    }

    .input-icon-search-root {
        background-color: $icon-input-search-background-color;
        height: 100%;
        border-radius: 21.5px;
    }

    #input-search-text {
        font-size: 12px;
        padding-left: 1em !important;
    }
}

.font-black {
    color: black
}

.font-grey {
    color: grey
}

.search-text-active, .search-text-match {
    background-color: $search-input-text-active-bgc !important;
}

.vehicles-flows-grid {
    height: 100%;
    width: 100%;

    .checkbox {
        padding: 7px !important;
    }
    
    .transport-sale {
        width: 100%;
        height: 100%;

        td, th {
            font-family: Roboto;
        }

        .k-grid-header {
            thead {
                background-color: $header-grid;
                height: 17px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.3px;
                line-height: 17px;

                th {
                    font-weight: 500;
                    color: #858889;
                }
            }
        }

        .k-grid-container {
            .k-grid-content {
                overflow-y: auto;

                .k-grid-table {
                    tbody {
                        .field-disabled {
                            color: $color-disabled;
                        }

                        .invoice-successfully {
                            color: $color-finished !important;
                            font-weight: bold;
                        }

                        .invoice-error {
                            color: red !important;
                            font-weight: bold;
                        }

                        .icon-regularization {
                            border: 1px solid black;
                            border-radius: 50%;
                            font-weight: bold;
                            background-color: red;
                            color: white;
                            width: 2em;
                            height: 2em;
                        }

                        .night-work {
                            background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='moon' class='svg-inline--fa fa-moon fa-w-16 fa-sm icon-night-work' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' > <path fill='currentColor' d='M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z'> </path > </svg >") no-repeat;
                            height: 15px;
                            width: 15px;
                        }
                    
                        .day-work {
                            background: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sun' class='svg-inline--fa fa-sun fa-w-16 fa-sm icon-night-work' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'> <path fill='currentColor' d='M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z'> </path> </svg>") no-repeat;
                            height: 15px;
                            width: 15px;
                        }

                        .icon-notification {
                            cursor: pointer;
                        }

                        .icon-notification-for-user {
                            color: $icon-user-color;
                        }

                        .icon-notification-for-marker.same-receiver-site {
                            color: deepskyblue;
                        }

                        .icon-notification-for-marker.different-receiver-sites {
                            color: magenta;
                        }

                        .header-flow {
                            height: 40px;
                            background-color: $color-row-light-blue;
                        }

                        .header-flow-regularization {
                            height: 40px;
                            background-color: $color-row-orange-light;
                        }

                        .header-request {
                            height: 40px;
                            background-color: $color-row-grey;
                        }

                        .vehicle {
                            height: 40px;
                            background-color: white;
                        }
                    }
                }
            }
        }

        .k-sort-order {
            color: transparent !important;
        }
    }
}

.icon-pen-blue {
    color: $icon-pen-blue;
}

.editable-header {
    cursor: pointer;
}

.editable-header-title {
    margin-right: 5px;
}

.drawer-margin {
    z-index: 0;
    width: $drawer-margin-width;
    flex-shrink: 0;

    .drawer-margin-paper {
        height: 100vh;
        width: $drawer-margin-width;

        .content-drawer-margin {
            flex-grow: 1;
            font-family: 'Roboto', sans-serif;
            background-color: $gray;

            .content-header-drawer-margin {
                background-color: $gray;

                .title-margin {
                    color: black;
                    font-weight: bold;
                    font-size: 1.2rem !important;
                    letter-spacing: -0.63px;
                }
            }

            .container-margin-grid {
                .k-grid {
                    height: unset !important;

                    .k-grid-container {
                        max-height: calc(100vh - #{$transport-sales-margin-grid-container-height});
                    }

                    thead {
                        background-color: $header-grid;
                    }

                    tfoot {
                        td {
                            padding: unset;
                            color: black;
                        }

                        .title-footer {
                            padding-left: 0.5em;
                        }
                    }

                    td.negative-margin {
                        color: $color-price-negative;
                        font-weight: bold;
                    }

                    td.positive-margin {
                        color: $color-price-positive;
                        font-weight: bold;
                    }

                    td, th {
                        font-family: Roboto;
                    }

                    .vehicle-type, .vehicle-type-header {
                        padding-left: 0.5em !important;
                    }

                    th {
                        color: $color-thead;
                    }

                    .k-grid-header th {
                        font-weight: 500;
                        color: #858889;
                    }
                }
            }
        }
    }
}

.popup-content {
    .k-header {
        padding: unset !important;

        .k-item {
            padding: 1px;
            border-bottom: 1px solid gray;

            .k-menu-link {
                padding: 4px 16px !important;

                .menu-item-div {
                    width: 100%;

                    .menu-item-camera, .menu-item-trash, .menu-item-regul-enabled, .menu-item-pen {
                        color: $button-actions-enabled;
                    }

                    .menu-item-disabled {
                        color: gray;
                    }

                    .menu-item-regul {
                        font-weight: bold;
                        text-align: center;
                    }

                    .menu-item-icon {
                        width: 33px;
                        padding-right: 13px;
                    }
                }
            }
        }
    }
}

.simple-dialog-regularization {
    .MuiDialog-paper {
        #draggable-dialog-title {
            cursor: move;
        }

        .MuiDialogTitle-root {
            background-color: $color-orange-light;
        }

        .loading-div {
            text-align: center;
        }

        .title {
            color: gray;
            font-size: 0.9em;
        }

        .vehicle-line {
            .vehicle-title {
                align-self: center;
                font-weight: 500;
                font-size: 1em;
            }

            .vehicle-removed {
                color: red;
            }

            .vehicle-added {
                color: $card-title-color;
            }

            .icon-regularization {
                border: 1px solid black;
                border-radius: 50%;
                font-weight: bold;
                background-color: red;
                color: white;
                width: 1.5em;
                height: 1.5em;
            }

            .k-numerictextbox {
                height: 40px !important;
                width: unset;
                border-radius: 4px;
                border: 1px solid $gray;
                background-color: $input-background-color;
                padding-right: unset;
                padding-left: 1em !important;

                .k-input-inner {
                    padding: 0;
                }
            }

            .txt-field-vehicle-comments {
                margin-top: 8px;
                border-radius: 4px;
                border: 1px solid $gray;
                background-color: $input-background-color;
                width: 100%;
                margin-right: 1em !important;

                .Mui-disabled:before, .Mui-focused {
                    border-bottom: unset !important;
                }

                .MuiInputBase-multiline {
                    padding: unset !important;

                    .MuiInputBase-inputMultiline {
                        padding: unset !important;
                    }
                }
            }
        }
    }

    .MuiDialog-paperWidthSm {
        max-width: unset;
        min-width: 900px;
        max-height: calc(100vh - 110px);
    }
}